

.app-container {
  display: flex;
  width: 100%;
  height: fit-content;
  background-color: #282c34;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.App {
  margin-top: 20px;
}

